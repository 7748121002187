<template>
  <b-container class="mt-2">
    <validation-observer #default="{handleSubmit}">
      <b-card header="Signup as Organization">
        <fieldset :disabled="$attrs.disabled">
          <b-form @submit.prevent>
            <div class="border p-2 mb-2">
              <h4 class="mb-2">
                Registration Data
              </h4>
              <b-row>
                <b-col md="6">
                  <text-input
                    id="first-name"
                    name="First Name"
                    rules="required|alpha|min:3|max:50"
                    :text.sync="entity.user_first_name"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="last-name"
                    name="Last Name"
                    rules="required|alpha|min:3|max:50"
                    :text.sync="entity.user_last_name"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="email"
                    name="Email"
                    rules="required|email"
                    :text.sync="entity.user_email"
                  />
                </b-col>
                <slot />
                <b-col md="6">
                  <text-input
                    id="password"
                    name="Password"
                    type="password"
                    rules="required|password"
                    :text.sync="entity.user_password"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="mobile"
                    name="Mobile"
                    rules="phone"
                    :text.sync="entity.user_mobile"
                  />
                </b-col>
                <b-col md="12">
                  <b-checkbox v-model="entity.terms">
                    Accept Terms and conditions
                  </b-checkbox>
                </b-col>
              </b-row>
            </div>
            <div class="border p-2 mb-2">
              <h4 class="mb-2">
                Organization Data
              </h4>
              <b-row>
                <b-col md="6">
                  <text-input
                    id="entity-name"
                    name="Entity Name"
                    rules="required|min:3|max:50"
                    :text.sync="entity.name"
                  />
                </b-col>
                <b-col md="6">
                  <custom-vue-select
                    id="org-type"
                    label="Org Type"
                    placeholder="Select Type"
                    rules="required"
                    :disabled="$attrs.disabled"
                    :value-reducer="option=>option.value"
                    :options="[
                      {text:'Islamic',value:'Organization'},
                      {text:'Business',value:'Business'},
                    ]"
                    :selected.sync="entity.classification"
                    text-field="text"
                  />
                </b-col>
                <b-col md="6">
                  <subclass-select
                    label="Subclass"
                    :multiple="true"
                    :disabled="$attrs.disabled"
                    rules="required"
                    :class-id="classLookup[entity.classification]"
                    :subclass.sync="entity.subclassifications"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="contact-email"
                    name="Contact Email"
                    rules="required|email"
                    :text.sync="entity.email"
                  />
                </b-col>
                <b-col md="12">
                  <upload-files
                    v-if="!$attrs.disabled"
                    id="media"
                    label="Media"
                    rules="size:5000|max:5"
                    multiple
                    accept="image/*"
                    :files.sync="entity.media_files"
                  />
                  <images-preview
                    :disabled="$attrs.disabled"
                    :images.sync="entity.media_files"
                  />
                </b-col>
                <b-col md="12">
                  <location-inputs :details="entity.location" />
                </b-col>
                <b-col md="12">
                  <text-area-input
                    id="description"
                    name="Description"
                    placeholder="Brief about place"
                    :text.sync="entity.description"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="border p-2 mb-2">
              <h4 class="mb-2">
                Organization social links
              </h4>
              <b-row>
                <b-col md="6">
                  <text-input
                    id="facebook"
                    name="Facebook"
                    rules="facebook-url"
                    :text.sync="entity.facebook"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="twitter"
                    name="Twitter"
                    rules="twitter-url"
                    :text.sync="entity.twitter"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="instagram"
                    name="Instagram"
                    rules="instagram-url"
                    :text.sync="entity.instagram"
                  />
                </b-col>
                <b-col md="6">
                  <text-input
                    id="website"
                    name="Website"
                    rules="url"
                    :text.sync="entity.website"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="text-right">
              <back class="mr-2" />
              <submit-button
                v-if="!$attrs.disabled"
                v-bind="{submit,handleSubmit}"
                :disabled="!entity.terms"
              />
            </div>
          </b-form>
        </fieldset>
      </b-card>
    </validation-observer>
  </b-container>
</template>

<script>
import Back from '../common/FormInputs/Back.vue'
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import LocationInputs from '../common/FormInputs/LocationInputs.vue'
import SubclassSelect from '../common/FormInputs/SubclassSelect.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'

export default {
  name: 'IndvidualUserForm',
  components: {
    TextInput,
    UploadFiles,
    ImagesPreview,
    TextAreaInput,
    SubmitButton,
    Back,
    LocationInputs,
    SubclassSelect,
    CustomVueSelect,
  },
  props: {
    entity: { type: Object, default: () => ({}) },
    submit: { type: Function, default: () => {} },
  },
  computed: {
    classLookup() {
      return {
        Organization: 1,
        Business: 2,
      }
    },
  },
}
</script>

<style>

</style>
